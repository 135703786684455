import React, { useEffect } from 'react';
import { Country } from '../../types/Country';

type Props = {
  className: string,
  country: Country;
  setBannerTextChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const DefaultBanner = ({
  className, country, setBannerTextChange,
}: Props): JSX.Element => {
  useEffect(() => {
    setBannerTextChange(true);
  }, []);

  return (
    <div className={className} id="text_banner">
      <div className="banner-content">
        Free shipping for monthly pack valued over ${country.freeShipping}
      </div>
    </div>
  );
};

export default DefaultBanner;
