/* eslint-disable react/no-danger */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useIntercom } from 'react-use-intercom';
import { EofyPopupWrapper } from '../pagestyles/components/EofyPopupWrapper';
import { BannerConfig } from '../types/Setting';
import Button from './common/button/Button';

type Props = {
  applyPopupPromoCode: () => void,
  bannerPopUpConfig: BannerConfig,
  showModal: boolean,
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
};

const EofyPopup: React.FC<Props> = ({
  applyPopupPromoCode, bannerPopUpConfig, showModal, setShowModal,
}: Props) => {
  const { boot } = useIntercom();

  const closeModal = () => {
    if (window && window.innerWidth) {
      if (window.innerWidth < 768) boot();
    }
    setShowModal(false);
  };

  return (
    <Modal show={showModal} dialogClassName="eofy-popup" onHide={() => closeModal()} centered>
      <EofyPopupWrapper bannerPopup={bannerPopUpConfig}>
        <button type="button" className="close" onClick={() => closeModal()}>
          <img src="/assets/images/popup/Close.png" alt="" />
        </button>
        <div className="content d-flex">
          <div className="left">
            <h1 className="campaign-name" dangerouslySetInnerHTML={{ __html: bannerPopUpConfig.popupPromoText }} />
            <h2 className="sale-off">{bannerPopUpConfig.popupTitleText}</h2>
            {/* <p className="subtitle" dangerouslySetInnerHTML={{ __html: bannerPopUpConfig.popupSubtitleText }} /> */}
            <Button
              style={{marginBottom: '92px'}}
              onClick={() => { applyPopupPromoCode(); }}
            >
              {bannerPopUpConfig.popupButtonText}
            </Button>
            <p className="subtitle" dangerouslySetInnerHTML={{ __html: bannerPopUpConfig.popupSubtitleText }} />
            <p className="disclaimer-text" dangerouslySetInnerHTML={{ __html: bannerPopUpConfig.popupDisclaimerText }} />
          </div>
          <div className="right">
            <p className="logo-stamp" />
          </div>
        </div>
      </EofyPopupWrapper>
    </Modal>
  );
};
export default EofyPopup;
