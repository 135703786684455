import React, { useEffect } from 'react';
import { BannerWrapper } from '../../pagestyles/components/HeaderWrapper';
import { Country } from '../../types/Country';
import { BannerConfig, Setting } from '../../types/Setting';
import AccountPageBanner from './AccountPageBanner';
import DefaultBanner from './DefaultBanner';
import InternationalBanner from './InternationalBanner';
import PromotionalBanner from './PromotionalBanner';

type Props = {
  asPath: string;
  bannerPopUpConfig: BannerConfig;
  className: string;
  country: Country;
  clickShowPromoPopup: () => void;
  setting: Setting;
  setBannerTextChange: React.Dispatch<React.SetStateAction<boolean>>
  useBannerPopUpCodePopup: boolean | null | string;
  widthScreen: number;
};

const Banner = ({
  asPath, bannerPopUpConfig, className, country, clickShowPromoPopup, setting, setBannerTextChange, useBannerPopUpCodePopup, widthScreen,
}: Props): JSX.Element => {
  useEffect(() => {
  }, []);
  let template: JSX.Element = <></>;
  if (country && !useBannerPopUpCodePopup) {
    template = <DefaultBanner className={className} country={country} setBannerTextChange={setBannerTextChange} />;
    if (country.country !== 'AU') {
      template = <InternationalBanner className={className} country={country} setBannerTextChange={setBannerTextChange} />;
    }
    if (asPath.includes('account')) {
      template = <AccountPageBanner className={className} country={country} setting={setting} setBannerTextChange={setBannerTextChange} />;
    }
  }

  if (useBannerPopUpCodePopup) {
    template = <PromotionalBanner bannerPopUpConfig={bannerPopUpConfig} clickShowPromoPopup={clickShowPromoPopup} setBannerTextChange={setBannerTextChange} widthScreen={widthScreen} />;
  }

  return (
    <BannerWrapper bannerPopup={bannerPopUpConfig}>
      {template}
    </BannerWrapper>
  );
};

export default Banner;
