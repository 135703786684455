/* eslint-disable react/no-danger */
import Link from 'next/link';
import React, { useEffect, useState, useRef } from 'react';
import {
  Dropdown, DropdownButton,
} from 'react-bootstrap';
import { useRouter } from 'next/router';
import find from 'lodash/find';
import useSetSafeTimeout from 'use-set-safe-timeout';
import { useAsyncEffect } from 'use-async-effect';
import { useIntercom } from 'react-use-intercom';
import dynamic from 'next/dynamic';
import { useAuth } from '../../containers/AuthContainer';
import { BannerConfig, Setting } from '../../types/Setting';
import { useCountry } from '../../containers/CountryContainer';
import { useSurvey } from '../../containers/SurveyContainer';
import { useHeader } from '../../containers/HeaderContainer';
import { useCart } from '../../containers/CartContainer';
import { usePromoCode } from '../../containers/PromoCodeContainer';
import { useLaunchDarkly } from '../../containers/LaunchDarklyContainer';
import { getPromoCode } from '../../common/next-util';
import {
  HEADER_DEFAULT_VERSION,
  HEADER_NEW_VERSION,
  PRODUCT_MENU_NAME_DEFAULT,
  PRODUCT_MENU_NAME_NEW,
} from '../../common/const';
import EofyPopup from '../EofyPopup';
import NewHeader from '../header/NewHeader';
import DefaultHeader from '../header/DefaultHeader';
import Banner from '../header/Banner';
import { sendGa4Event } from '../../common/GTM';

const ModalUnfinishedQuiz = dynamic(import('../ModalUnfinishedQuiz'));
const ModalRedoQuiz = dynamic(import('../ModalRedoQuiz'));
const ModalPromoCodeApplied = dynamic(import('../ModalPromoCodeApplied'));

type Props = {
  setting: Setting;
};

const defaultBannerConfig: BannerConfig = {
  enableBanner: false,
  enablePopup: false,
  autoPopup: false,
  autoPopupTime: 20,
  bannerText: 'LUNAR NEW YEAR - 25% OFF SITEWIDE',
  bannerTextMobile: 'LUNAR NEW YEAR - 25% OFF SITEWIDE',
  bannerColor: '#bed0a2',
  popupPromo: 'GIFT50',
  popupBackground: '/assets/images/popup/eofy-bg.png',
  popupBackgroundMobile: '/assets/images/popup/eofy-bg-m.png',
  popupButtonColor: '#f5785a',
  popupButtonText: 'Take The Expert Quiz',
  popupPromoText: 'Mid-year Sale!',
  popupPromoBackgroundColor: '#F2EDE0',
  popupTitleText: '40% off sitewide',
  popupTitleColor: '',
  popupTitleSize: 48,
  popupSubtitleText: 'Discount applied at checkout',
  popupSubtitleColor: '',
  popupSubtitleSize: 17,
  popupDisclaimerText: 'First-time customer only',
  popupDisclaimerColor: '',
  bannerLinkText: 'Click here for details',
  bannerLinkTextMobile: 'Click here for details',
  bannerLinkTextUnderline: true,
  showPopupBorder: true,
  popupTitleTextBackground: 'black',
  popupPromoColor: 'black',
  popupPromoTextSize: 48,
  countries: '',
};

const logoVDynamic = 'logo-img';
const logoVitableStatic = 'logo-img-with-cart';

const Header: React.FC<Props> = ({ setting }: Props) => {
  const router = useRouter();
  const country = useCountry((container) => container.country);
  const cart = useCart((container) => container.cart);
  const showHeader = useHeader((container) => container.showHeader);
  const isShowSideBar = useHeader((container) => container.isShowSideBar);
  const setIsShowSideBar = useHeader((container) => container.setIsShowSideBar);
  const headerVersion = useHeader((container) => container.headerVersion);
  const setHeaderVersion = useHeader((container) => container.setHeaderVersion);
  const setBannerTextChange = useHeader((container) => container.setBannerTextChange);
  const user = useAuth((container) => container.user);
  const customer = useAuth((container) => container.customer);
  const logout = useAuth((container) => container.logout);
  const showModalUnfinishedQuiz = useSurvey((container) => container.showModalUnfinishedQuiz);
  const setShowModalUnfinishedQuiz = useSurvey((container) => container.setShowModalUnfinishedQuiz);
  const surveyPageRouter = useSurvey((container) => container.surveyPageRouter);
  const showModalRedoQuiz = useSurvey((container) => container.showModalRedoQuiz);
  const setShowModalRedoQuiz = useSurvey((container) => container.setShowModalRedoQuiz);
  const isRedoText = useSurvey((container) => container.isRedoText);
  const code = usePromoCode((container) => container.code);
  const setPromoCode = usePromoCode((container) => container.setPromoCode);
  const getVariation = useLaunchDarkly((container) => container.getVariation);
  const trackEvent = useLaunchDarkly((container) => container.trackEvent);
  const { shutdown, boot } = useIntercom();
  const setSafeTimeout = useSetSafeTimeout();
  const [isHoverLearn, setIsHoverLearn] = useState(false);
  const [isHoverAccount, setIsHoverAccount] = useState(false);
  const [sidebarLevel2, setSidebarLevel2] = useState(false);
  const [sidebarLevel2Name, setSidebarLevel2Name] = useState(false);
  const [showCart, setShowCart] = useState(false);
  const [oneOffCartLength, setOneOffCartLength] = useState<number>(0);
  const [scrolled, setScrolled] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [popUpBannerCode, setPopUpBannerCode] = useState<string | null>(null);
  const [widthScreen, setWidthScreen] = useState<number>(0);
  const [showDiscountPopup, setShowDiscountPopup] = useState<boolean>(false);
  const [paramCode, setParamCode] = useState<string>('');
  const [bannerPopUpConfig, setBannerPopUpConfig] = useState<BannerConfig>(defaultBannerConfig);
  const [isMobileScreen, setIsMobileScreen] = useState<boolean>(false);
  const [scrollUp, setScrollUp] = useState<boolean>(false);
  const [styleLogo, setStyleLogo] = useState<string>('logo-img');
  const [takeQuizBtnClass, setTakeQuizBtnClass] = useState<string>('');
  const [alignLogo, setAlignLogo] = useState<boolean>(false);
  const [productMenuVariant, setProductMenuVariant] = useState<string>(PRODUCT_MENU_NAME_DEFAULT);
  const clearLastTimeoutRef = useRef<(() => void) | null>(null);
  const name: string = customer ? customer.nameOnPack : '';
  const cartLength = cart?.length;
  const { asPath } = router;

  const handleScroll = () => {
    setWidthScreen(window.innerWidth);
    const offset = window.scrollY;
    if (offset > 70) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const closeAllMenu = () => {
    setIsShowSideBar(false);
    boot();
    setSidebarLevel2(false);
    setSidebarLevel2Name(false);
  };

  const logOutAndCloseMenu = () => {
    closeAllMenu();
    logout();
  };

  const getTakeQuizBtnClass = () => {
    let defaultClass = 'button-nav-right hide-on-mobile';
    const displayOnMobileUrls = ['/blog'];
    const displayBtn = find(displayOnMobileUrls, (path) => asPath.includes(path));
    if (displayBtn && cartLength === 0) {
      defaultClass = 'button-nav-right mobile-style';
      setAlignLogo(true);
    }
    setTakeQuizBtnClass(defaultClass);
  };

  const showSideBar = () => {
    setIsShowSideBar(!isShowSideBar);
    setShowBanner(!showBanner);
    setIsHoverAccount(false);
    if (isShowSideBar) return shutdown();
    return boot();
  };
  const showMenuHigher = () => setSidebarLevel2(!sidebarLevel2);
  const showMenuName = () => setSidebarLevel2Name(!sidebarLevel2Name);

  let lastScrollTop = 0;
  const scrollDirection = () => {
    const st = window.pageYOffset;
    const scrollingUp = !(st > lastScrollTop);
    setScrollUp(scrollingUp);
    lastScrollTop = st <= 0 ? 0 : st;
  };

  const hideWhenScrollUp = (): boolean => {
    const applyUrls = ['/products/'];
    let hideIt = false;
    const allowUrl = find(applyUrls, (path) => asPath.includes(path));
    if (allowUrl || !showHeader) {
      if (isMobileScreen) {
        hideIt = !scrollUp;
      }
    }
    return hideIt;
  };

  const navbarClasses = ['header', 'container-fluid'];
  const hideBanner = [''];
  if (scrolled) {
    navbarClasses.push('scrolled');
    hideBanner.push('hide-banner');
    if (hideWhenScrollUp()) {
      navbarClasses.push('d-none');
    }
  }

  const displayHeader = (): boolean => {
    let display = true;
    const disableMobileUrls = ['/mobile-app'];
    const notDisplayUrls = [
      '/survey',
      '/checkout',
      '/quick-quiz',
      '/recommendation',
      '/404',
      '/500',
      '/label',
      '/maintenance',
      '/mobile-app',
      '/unsubscribe',
      'widgets',
    ];
    const notAllow = find(notDisplayUrls, (path) => asPath.includes(path));
    if (notAllow || !showHeader) display = false;
    const mobileDisable = find(disableMobileUrls, (path) =>
      asPath.includes(path));
    if (mobileDisable && isMobileScreen) display = false;
    return display;
  };

  const displayOneOffCartIcon = (): void => {
    const oneOffCart = user ? cart?.filter(f => f.product?.availableForOneOff) : [];
    
    setOneOffCartLength(oneOffCart.length);
    setStyleLogo(logoVDynamic);
  };


  const clearTimer = () => {
    // console.log('clearLastTimeoutRef.current :', clearLastTimeoutRef.current);
    if (clearLastTimeoutRef.current) clearLastTimeoutRef.current();
  };

  const doShowDiscountPopup = () => {
    if (code === '' && !user) {
      trackEvent('show-black-friday-popup');
      // getVariation('black-friday-promo-code', DEFAULT_POPUP_BANNER_CODE); // refetch to track variation
      getVariation('custom-banner-popup-config', defaultBannerConfig);
      if (window && window.innerWidth) {
        if (window.innerWidth < 768) shutdown();
      }
      setShowDiscountPopup(true);
      clearTimer();
    }
  };

  const startTimer = (delay: number) => {
    // console.log('startTimer :', delay);
    clearLastTimeoutRef.current = setSafeTimeout(() => {
      // console.log('popup timeout');
      doShowDiscountPopup();
    }, delay * 1000);
    // console.log('clearLastTimeoutRef.current :', clearLastTimeoutRef.current);
  };

  const applyPopupPromoCode = () => {
    // console.log('blackFridayCode', blackFridayCode);
    if (popUpBannerCode) {
      setPromoCode(popUpBannerCode);
      sendGa4Event('promotionalPopup', 'activation', popUpBannerCode);
      router.push('/survey');
    }
    trackEvent('click-apply-black-friday-code');
    setShowDiscountPopup(false);
  };

  const renderModal = (): JSX.Element => {
    const showIt = showDiscountPopup
      && !user
      && bannerPopUpConfig.enablePopup
      && popUpBannerCode !== null;
    return (
      <EofyPopup
        applyPopupPromoCode={applyPopupPromoCode}
        bannerPopUpConfig={bannerPopUpConfig}
        showModal={showIt}
        setShowModal={setShowDiscountPopup}
      />
    );
  };

  const clickShowPromoPopup = () => {
    doShowDiscountPopup();
    if (bannerPopUpConfig) {
      sendGa4Event('banner', 'click', `${bannerPopUpConfig.bannerText}-${bannerPopUpConfig.popupPromo}`);
    }
  };
	
  const sendGa4EventAccDropdownMenu = (label: string): void => { 
    sendGa4Event('accDropdownMenu', 'click', label);
  }

  const accountItems = () => {
    let content = (
      <DropdownButton
        className="account"
        title={name}
        onMouseEnter={() => setIsHoverAccount(true)}
        onMouseLeave={() => setIsHoverAccount(false)}
        show={isHoverAccount}
      >
        <Link href="/myaccount/profile-customer" passHref>
          <Dropdown.Item className="chevron-right profile-customer" onClick={()=> sendGa4EventAccDropdownMenu('myProfile')}>
            Profile
          </Dropdown.Item>
        </Link>
        <Link href="/myaccount/next-delivery" passHref>
          <Dropdown.Item className="chevron-right next-delivery" onClick={()=> sendGa4EventAccDropdownMenu('myPack')}>
            My pack
          </Dropdown.Item>
        </Link>
        <Link href="/myaccount/my-rewards" passHref>
          <Dropdown.Item className="chevron-right my-rewards" onClick={()=> sendGa4EventAccDropdownMenu('myRewards')}>
            My rewards
          </Dropdown.Item>
        </Link>
        <Dropdown.Item className="btn-logout" onClick={() => logout()}>
          Log Out
        </Dropdown.Item>
      </DropdownButton>
    );
    if (window !== undefined && window.innerWidth < 992) {
      content = (
        <DropdownButton
          className={`account${isShowSideBar ? ' sidebar-active' : ''}`}
          title={name}
          onClick={() => setIsHoverAccount(!isHoverAccount)}
          show={isHoverAccount}
        >
          <Link href="/myaccount/profile-customer" passHref>
            <Dropdown.Item className="chevron-right profile-customer" onClick={()=> sendGa4EventAccDropdownMenu('myProfile')}>
              Profile
            </Dropdown.Item>
          </Link>
          <Link href="/myaccount/next-delivery" passHref>
            <Dropdown.Item className="chevron-right next-delivery" onClick={()=> sendGa4EventAccDropdownMenu('myPack')}>
              My pack
            </Dropdown.Item>
          </Link>
          <Link href="/myaccount/my-rewards" passHref>
            <Dropdown.Item className="chevron-right my-rewards" onClick={()=> sendGa4EventAccDropdownMenu('myRewards')}>
              My rewards
            </Dropdown.Item>
          </Link>
          <Dropdown.Item className="btn-logout" onClick={() => logout()}>
            Log Out
          </Dropdown.Item>
        </DropdownButton>
      );
    }
    return content;
  };

  const logoStylesClass = () => {

    let logoStyle = showCart ? logoVDynamic : logoVitableStatic;
    if (alignLogo && !showCart) {
      logoStyle += ' logo-position';
    }
    setStyleLogo(logoStyle);
  };

  const productLibraryClick =( e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    router.push('/products');
  }

  useEffect(() => {
    const noVertical = 992;
    if (window.innerWidth < noVertical) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
    window.addEventListener('scroll', scrollDirection);
    return () => {
      window.removeEventListener('scroll', scrollDirection);
    };
  }, []);

  useEffect(() => {
    logoStylesClass();
    getTakeQuizBtnClass();
  }, [showCart, cartLength, alignLogo, asPath]);

  useEffect(() => {
    setShowCart(!user && cartLength > 0);
    displayOneOffCartIcon();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [cartLength, user]);

  useAsyncEffect(async () => {
    const getFlag = await getVariation('persistent-cta-in-menu', false);
    if (getFlag === HEADER_DEFAULT_VERSION) {
      setHeaderVersion(HEADER_DEFAULT_VERSION);
    } else {
      setHeaderVersion(HEADER_NEW_VERSION);
    }
    const getFlagProducts = await getVariation('product-library-menu-name-experiment', PRODUCT_MENU_NAME_DEFAULT);
    if (getFlagProducts === PRODUCT_MENU_NAME_NEW) {
      setProductMenuVariant(PRODUCT_MENU_NAME_NEW);
    } else {
      setProductMenuVariant(PRODUCT_MENU_NAME_DEFAULT);
    }
  }, []);

  useAsyncEffect(async () => {
    let queryCode = '';
    if (router.query && (router.query.ref || router.query.promo)) {
      const promoCode = router.query.ref
        ? router.query.ref
        : router.query.promo;
      if (promoCode) queryCode = promoCode.toString();
    }
    if (queryCode.length > 0) setParamCode(queryCode);
    if (
      code === ''
      && !user
      && queryCode === ''
      && !bannerPopUpConfig.enableBanner
      && country
    ) {
      // console.log('checking for black friday');
      let bannerConfigData = await getVariation(
        'custom-banner-popup-config',
        defaultBannerConfig,
      );
      if (bannerConfigData.countries && bannerConfigData.countries !== '') {
        if (!bannerConfigData.countries.includes(country.country)) {
          bannerConfigData = { ...bannerConfigData, enableBanner: false };
        }
      }
      setBannerPopUpConfig(bannerConfigData);
      if (bannerConfigData && bannerConfigData.popupPromo && bannerConfigData.enableBanner) {
        const promo = await getPromoCode(bannerConfigData.popupPromo);

        if (!promo || !promo.valid) return;
        setPopUpBannerCode(bannerConfigData.popupPromo);
        const { autoPopup } = bannerConfigData;
        // console.log('autoPopup :', autoPopup);
        if (autoPopup) {
          const delay = bannerConfigData.autoPopupTime;
          startTimer(delay);
        }
      }
    }
  }, [country, code, router.query]);

  const bannerClass = `banner ${hideBanner.join(' ')}`;
  const useBannerPopUpCodePopup = !user && code === '' && bannerPopUpConfig.enableBanner && popUpBannerCode;
  return displayHeader() ? (
    <>
      {showBanner && !hideWhenScrollUp() && country && (
        <Banner
          asPath={asPath}
          bannerPopUpConfig={bannerPopUpConfig}
          className={bannerClass}
          country={country}
          clickShowPromoPopup={clickShowPromoPopup}
          setting={setting}
          setBannerTextChange={setBannerTextChange}
          useBannerPopUpCodePopup={useBannerPopUpCodePopup}
          widthScreen={widthScreen}
        />
      )}
      {renderModal()}
      {headerVersion && headerVersion !== '' && (
        <>
          {headerVersion === 'default' ? (
            <DefaultHeader
              accountItems={accountItems}
              closeAllMenu={closeAllMenu}
              cartLength={cartLength}
              isHoverLearn={isHoverLearn}
              isShowSideBar={isShowSideBar}
              isRedoText={isRedoText}
              showCart={showCart}
              oneOffCartLength={oneOffCartLength}
              showSideBar={showSideBar}
              showMenuHigher={showMenuHigher}
              setIsHoverLearn={setIsHoverLearn}
              surveyPageRouter={surveyPageRouter}
              sidebarLevel2={sidebarLevel2}
              navbarClasses={navbarClasses}
              productLibraryClick={productLibraryClick}
              user={user}
              takeQuizBtnClass={takeQuizBtnClass}
              styleLogo={styleLogo}
              productMenuName={productMenuVariant}
              setting={setting}
            />
          ) : (
            <NewHeader
              accountItems={accountItems}
              closeAllMenu={closeAllMenu}
              cartLength={cartLength}
              isHoverLearn={isHoverLearn}
              isShowSideBar={isShowSideBar}
              isRedoText={isRedoText}
              logOutAndCloseMenu={logOutAndCloseMenu}
              setIsHoverLearn={setIsHoverLearn}
              showCart={showCart}
              showMenuName={showMenuName}
              showSideBar={showSideBar}
              showMenuHigher={showMenuHigher}
              sidebarLevel2Name={sidebarLevel2Name}
              surveyPageRouter={surveyPageRouter}
              sidebarLevel2={sidebarLevel2}
              name={name}
              navbarClasses={navbarClasses}
              user={user}
              takeQuizBtnClass={takeQuizBtnClass}
              styleLogo={styleLogo}
              productMenuName={productMenuVariant}
              setting={setting}
            />
          )}
        </>
      )}
      <ModalUnfinishedQuiz
        showModal={showModalUnfinishedQuiz}
        setShowModal={setShowModalUnfinishedQuiz}
      />
      <ModalRedoQuiz
        showModal={showModalRedoQuiz}
        setShowModal={setShowModalRedoQuiz}
        hideButtonRecommendation={false}
      />
      {paramCode.length > 0 && user && (
        <ModalPromoCodeApplied promoCode={paramCode} />
      )}
    </>
  ) : null;
};
export default Header;
