import React, { useEffect } from 'react';
import { BannerConfig } from '../../types/Setting';

type Props = {
  bannerPopUpConfig: BannerConfig;
  clickShowPromoPopup: () => void;
  setBannerTextChange: React.Dispatch<React.SetStateAction<boolean>>
  widthScreen: number;
};

const PromotionalBanner = ({
  bannerPopUpConfig, clickShowPromoPopup, setBannerTextChange, widthScreen,
}: Props): JSX.Element => {
  useEffect(() => {
    setBannerTextChange(true);
  }, []);
  let linkText = '';
  if (bannerPopUpConfig.enablePopup) {
    linkText = bannerPopUpConfig.bannerLinkText;
  }

  if (widthScreen < 500) {
    if (bannerPopUpConfig.enablePopup) {
      linkText = bannerPopUpConfig.bannerLinkTextMobile;
    }
    return (
      <div className="banner black" id="text_banner">
        <button type="button" className="link-text" onClick={() => clickShowPromoPopup()}>
          {bannerPopUpConfig.bannerTextMobile} <u>{linkText}</u>
        </button>
      </div>
    );
  }

  return (
    <div className="banner black" id="text_banner">
      {bannerPopUpConfig.bannerText}&nbsp;
      <button type="button" className="link-text" onClick={() => clickShowPromoPopup()}>
        <u>{linkText}</u>
      </button>
    </div>
  );
};

export default PromotionalBanner;
