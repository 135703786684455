/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import {
  Col, Dropdown, DropdownButton, Row,
} from 'react-bootstrap';
import { find } from 'lodash';
import { useRouter } from 'next/router';
import { useRecommendation } from '../../containers/RecommendationContainer';
import { FACEBOOK_LINK, FAQ_LINK, INSTAGRAM_LINK, QUIZ_BTN_DEFAULT_TEXT } from '../../common/const';
import { DefaultHeaderWrapper } from '../../pagestyles/components/DefaultHeaderWrapper';
import { User } from '../../types/User';
import { Setting } from '../../types/Setting';
import { sendGa4Event } from '../../common/GTM';

type Props = {
  accountItems: () => void,
  closeAllMenu: () => void,
  cartLength: number,
  isHoverLearn: boolean,
  isShowSideBar: boolean,
  isRedoText: boolean,
  showCart: boolean,
  oneOffCartLength: number,
  showSideBar: () => void,
  showMenuHigher: () => void,
  setIsHoverLearn: React.Dispatch<React.SetStateAction<boolean>>,
  surveyPageRouter: (event: string) => void,
  sidebarLevel2: boolean,
  navbarClasses: string[],
  user: User | null,
  takeQuizBtnClass: string,
  styleLogo: string,
  productLibraryClick: (e: React.MouseEvent<Element, MouseEvent>)=> void,
  productMenuName: string
  setting: Setting,
};
const DefaultHeader = ({
  accountItems,
  closeAllMenu,
  cartLength,
  isHoverLearn,
  isShowSideBar,
  isRedoText,
  setIsHoverLearn,
  productLibraryClick,
  showCart,
  oneOffCartLength,
  showSideBar,
  showMenuHigher,
  surveyPageRouter,
  sidebarLevel2,
  navbarClasses,
  user,
  takeQuizBtnClass,
  styleLogo,
  productMenuName,
  setting,
}: Props): JSX.Element => {
  const [css, setCss] = useState('');
  const firstName = useRecommendation((container) => container.firstName);
  const router = useRouter();

  useEffect(() => {
    if ((firstName && firstName !== '') || user) {
      setCss('d-none d-sm-flex');
    }
  }, [firstName, user]);

  const displayBtnTakeQuiz = (): boolean => {
    let show = false;
    const displayQuizBtnUrls = ['/blog'];
    const display = find(displayQuizBtnUrls, (path) => router.asPath.includes(path));
    if (display && !showCart && !user) {
      show = true;
    }
    return show;
  };
	
  const sendGa4EventTakeQuiz = () => {
    const isMyProfilePage = router.asPath.includes('/myaccount/profile-customer');
    if (isMyProfilePage) {
      sendGa4Event('takeQuiz', 'click');
    }
  }

  return (
    <DefaultHeaderWrapper user={!!user}>
      <header className={navbarClasses.join(' ')}>
        <Row>
          <Col className="column1" xs={3} sm={4} md={4} lg={5} xl={4}>
            <div className="nav-left">
              <div className="background-quiz nav-left__item">
                <a
                  data-testid='takeQuizBtn'
                  role="button"
                  aria-label="Start Quiz"
                  href="/#"
                  onClick={(e) => { e.preventDefault(); surveyPageRouter(isRedoText ? 'click-on-desktop-retake-quiz-cta' : 'click-on-desktop-take-quiz-cta'); }}
                  className="button-quiz"
                  dangerouslySetInnerHTML={{ __html: QUIZ_BTN_DEFAULT_TEXT }}
                />
              </div>
              <div className="nav-left__item">
                <Link href="/products">
                  <a href="/products" className="button-library" onClick={(e) => { productLibraryClick(e); }}>
                    {productMenuName}
                  </a>
                </Link>
              </div>
              {user && (
                <div className='gift-card'>
                  <Link href='/products/dna-test'>
                    <a href='/products/dna-test' className='button-gift-card'>
                      Vitable DNA test
                      <span className='new-tag'>NEW</span>
                    </a>
                  </Link>
                </div>
              )}
            </div>
            <div className="navbar-mobile">
              <div className="navbar-icon">
                <button type="button" onClick={showSideBar}>
                  <div id="nav-icon1">
                    <span />
                    <span />
                    <span />
                  </div>
                </button>
              </div>
              <nav className={isShowSideBar ? 'nav-menu active' : 'nav-menu'}>
                <ul className="nav-items">
                  <li
                    className="nav-toggle d-flex justify-content-between"
                    aria-hidden="true"
                  >
                    <button
                      type="button"
                      className="icon-close"
                      aria-label="close"
                      onClick={showSideBar}
                    />
                  </li>
                  <li
                    className="nav-toggle"
                    onClick={showSideBar}
                    aria-hidden="true"
                  >
                    <a
                      role="button"
                      aria-label="Start Quiz"
                      href="/#"
                      onClick={(e)=> {e.preventDefault(); surveyPageRouter(isRedoText ? 'click-on-retake-quiz-in-mobile-hamburger-menu' : 'click-take-quiz-in-mobile-hamburger-menu')}}
                      className= "button-quiz"
                      dangerouslySetInnerHTML={{__html: QUIZ_BTN_DEFAULT_TEXT}}
                    />
                  </li>
                  <li
                    className="nav-toggle"
                    onClick={showSideBar}
                    aria-hidden="true"
                  >
                    <Link href="/products" passHref>
                      <a href="/products" className="button-library"
                        onClick={(e) => {productLibraryClick(e); }}>
                        {productMenuName}</a>
                    </Link>
                  </li>
                  {user && (
                    <li className='nav-toggle' onClick={showSideBar} aria-hidden='true'>
                      <Link href='/products/dna-test'>
                        <a href='/products/dna-test' className='button-gift-card'>
                          Vitable DNA test
                          <div className='new-tag'>NEW</div>
                        </a>
                      </Link>
                    </li>
                  )}
                  {user && (
                    <li
                      className="nav-toggle referral-credit"
                      onClick={showSideBar}
                      aria-hidden="true"
                    >
                      <Link href="/myaccount/my-rewards" passHref>
                        <a href="/myaccount/my-rewards">Get $30 Off</a>
                      </Link>
                    </li>
                  )}
                  <li className="nav-toggle">
                    <button
                      type="button"
                      className="btn-learn"
                      id="dropdown-learn"
                      onClick={showMenuHigher}
                    >
                      Learn <span className="chevron-right" />
                    </button>
                    <nav
                      className={sidebarLevel2 ? 'nav-menu active' : 'nav-menu'}
                    >
                      <ul className="nav-items">
                        <li
                          className="nav-toggle title-menu"
                          aria-label="Learn"
                          onClick={showMenuHigher}
                          aria-hidden="true"
                        >
                          <button
                            type="button"
                            className="btn-learn"
                            aria-label="Learn"
                          >
                            <span className="chevron-left" />
                          </button>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={closeAllMenu}
                          aria-hidden="true"
                        >
                          <Link href="/about" passHref>
                            <a href="/about" className="about">About Us</a>
                          </Link>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={closeAllMenu}
                          aria-hidden="true"
                        >
                          <Link href="/sustainability" passHref>
                            <a href="/sustainability" className="sustainability">Sustainability</a>
                          </Link>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={closeAllMenu}
                          aria-hidden="true"
                        >
                          <Link href={FAQ_LINK} passHref>
                            <a target="_blank" href={FAQ_LINK} rel="noopener noreferrer" className="faqs">FAQs</a>
                          </Link>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={closeAllMenu}
                          aria-hidden="true"
                        >
                          <Link href="/blog" passHref>
                            <a href="/blog" className="blog">Blog</a>
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </li>
                  <li
                    className="nav-toggle reviews"
                    onClick={showSideBar}
                    aria-hidden="true"
                  >
                    <Link href="/reviews" passHref>
                      <a href="/reviews">Reviews</a>
                    </Link>
                  </li>
                  {setting.giftCardEnabled && (
                    <li
                      className="nav-toggle gift-card"
                      onClick={showSideBar}
                      aria-hidden="true"
                    >
                      <Link href="/gift-card" passHref>
                        <a href="/gift-card">Send e-gift</a>
                      </Link>
                    </li>
                  )}
                  {!user && (
                    <li
                      className="nav-toggle"
                      onClick={showSideBar}
                      aria-hidden="true"
                    >
                      <Link href="/auth/login" passHref>
                        <a href="/auth/login">My Account</a>
                      </Link>
                    </li>
                  )}
                  <div className="social">
                    <a
                      className="social-link instagram"
                      href={INSTAGRAM_LINK}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Instagram
                    </a>
                    <a
                      className="social-link facebook"
                      href={FACEBOOK_LINK}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Facebook
                    </a>
                  </div>
                </ul>
              </nav>
            </div>
          </Col>
          <Col className="column2" xs={6} sm={4} md={4} lg={3} xl={4}>
            <div className={`logo ${css}`}>
              <Link href="/">
                <div className={styleLogo} />
              </Link>
            </div>
          </Col>
          <Col className="column3" xs={3} sm={4} md={4} lg={4} xl={4}>
            <div className="nav-right">
              <div className='gift-card'>
                <DropdownButton
                  id="dropdown-learn"
                  className="nav-left__item"
                  title="Learn"
                  onMouseEnter={() => setIsHoverLearn(true)}
                  onMouseLeave={() => setIsHoverLearn(false)}
                  show={isHoverLearn}
                >
                  <Link href="/about" passHref>
                    <Dropdown.Item className="about"> About us</Dropdown.Item>
                  </Link>
                  <Link href="/sustainability" passHref>
                    <Dropdown.Item className="sustainability">Sustainability</Dropdown.Item>
                  </Link>
                  <Dropdown.Item target="_blank" href={FAQ_LINK} rel="noopener noreferrer" className="faqs">FAQs</Dropdown.Item>
                  <Link href="/blog" passHref>
                    <Dropdown.Item className="blog text-left">Blog</Dropdown.Item>
                  </Link>
                  <Link href="/reviews" passHref>
                    <Dropdown.Item className="reviews text-left">Reviews</Dropdown.Item>
                  </Link>
                </DropdownButton>
              </div>
              {setting.giftCardEnabled && (
                <div className="gift-card">
                  <Link href="/gift-card">
                    <a href="/gift-card" className="button-gift-card">
                      Send e-gift
                    </a>
                  </Link>
                </div>
              )}
              {!user && (
                <>
                  <div className="my-account">
                    <Link href="/auth/login">
                      <a href="/auth/login" className="button-login">
                        My Account
                      </a>
                    </Link>
                  </div>
                  {firstName && (
                    <div className="recommendation">
                      <Link href="/recommendation">
                        <a href="/recommendation" className="button-login">
                          {firstName}
                        </a>
                      </Link>
                    </div>
                  )}
                </>
              )}
              {displayBtnTakeQuiz() && (
                <div className={takeQuizBtnClass}>
                  <a
                    role="button"
                    aria-label="StartQuiz"
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      sendGa4EventTakeQuiz();
                      surveyPageRouter( 'click-on-desktop-take-quiz-cta');
                    }}
                    className="button-quiz"
                    dangerouslySetInnerHTML={{
                      __html: QUIZ_BTN_DEFAULT_TEXT,
                    }}
                  />
                </div>
              )}
              {user && (
                <div>
                  <div className="my-rewards">
                    <Link href="/myaccount/my-rewards" passHref>
                      <a href="/myaccount/my-rewards">Get $30 Off</a>
                    </Link>
                  </div>
                  {accountItems()}
                </div>
              )}
              {oneOffCartLength > 0 && (
                <div className='logo-cart'>
                  <Link href='/checkout/one-off'>
                    <div className='cart-img'>
                      <div className='cart-number'>{oneOffCartLength}</div>
                    </div>
                  </Link>
                </div>
              )}
              {showCart && (
                <div className="logo-cart">
                  <Link href="/checkout/review">
                    <div className="cart-img">
                      <div className="cart-number"> {cartLength}</div>
                    </div>
                  </Link>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </header>
    </DefaultHeaderWrapper>
  );
};
export default DefaultHeader;
