/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  black, grotesk, solaire,
} from '../../../styles/theme';
import { BannerConfig } from '../../types/Setting';

export const EofyPopupWrapper = styled.div<{ bannerPopup: BannerConfig }>`
  ${(props) =>
    props.bannerPopup
    && `
    background-color: ${props.bannerPopup.popupPromoBackgroundColor || '#F2EDE0'};
    border-radius: 30px;

    .close {
      position: absolute;
      top: 7px;
      right: 7px;
      width: 40px;
      height: 40px;
      background: none;
      border: none;
      padding: 0;
      z-index: 1;

      img {
        width: 40px;
        height: 40px;
      }
    }

    .left, .right {
      border-radius: 30px;
    }
    

    .left {
      width: 388px;
      padding: 116px 26px 17px;
    }

    .right {
      width: calc(100% - 388px);
      background-image: url(${props.bannerPopup.popupBackground});
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-color: ${props.bannerPopup.popupPromoBackgroundColor};
      position: relative;
      border-radius: 30% / 50%;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;

      .logo-stamp {
        background-image: url('/assets/images/LogoStamp.svg');
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        -webkit-animation: rotation 7s infinite linear;
        -moz-animation: rotation 7s infinite linear;
        animation: rotation 7s infinite linear;
        -webkit-transform-origin: center;
        -moz-transform-origin: center;
        transform-origin: center;
        display: block;
        position: absolute;
        width: 70px;
        height: 70px;
        top: 54px;
        left: -15px;
        margin-bottom: 0;
      }
    }
    
    .campaign-name {
      color: ${props.bannerPopup.popupPromoColor || black};
      font-size: ${props.bannerPopup.popupPromoTextSize || 48}px;
      font-family: ${solaire};
      position: relative;
      line-height: 40px;
      font-weight: 400;
      margin-bottom: 20px;

      // &:before {
      //   content: '';
      //   position: absolute;
      //   background-image: url('/assets/images/popup/Sparkle.png');
      //   background-size: 100%;
      //   background-position: center;
      //   background-repeat: no-repeat;
      //   width: 41px;
      //   height: 41px;
      //   bottom: 36px;
      //   left: 24px;
      // }
    }

    .sale-off {
      color: ${props.bannerPopup.popupTitleColor || black};
      font-size: ${props.bannerPopup.popupTitleSize || 48}px;
      font-family: ${solaire};
      line-height: 40px;
      font-weight: 400;
      margin-bottom: 44px;

      &:after {
        content: '';
        position: absolute;
        background-image: url('/assets/images/popup/Arrow_04.png');
        background-size: 100%;
        background-position: center;
        background-repeat: no-repeat;
        width: 72px;
        height: 72px;
        top: 221px;
        left: 322px;
      }
    }

    .subtitle {
      color: ${props.bannerPopup.popupSubtitleColor || black};
      font-size: ${props.bannerPopup.popupSubtitleSize || 14}px;
      font-family: ${grotesk};
      font-style: italic;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 0;
    }

    .btn-apply {
      padding: 16px 40px;
      margin-bottom: 92px;
    }

    .disclaimer-text {
      color: ${props.bannerPopup.popupDisclaimerColor || black};
      font-family: ${grotesk};
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1024px) {
      border-radius: 26px;

      .content {
        flex-direction: column-reverse;
      }

      .left, .right {
        width: 100%;
        border-radius: 26px;
      }

      .left {
        padding: 31px 21px 15px;
      }
      
      .right {
        height: 200px;
        background-image: url(${props.bannerPopup.popupBackgroundMobile});
        border-radius: 62% / 20%;
        border-top-left-radius: 26px;
        border-top-right-radius: 26px;

        .logo-stamp {
          background-image: url('/assets/images/popup/LogoStamp-half.png');
          animation: unset;
          transform-origin: unset;
          width: 35px;
          left: 0;
          top: 135px;
        }
      }

      .campaign-name {
        font-size: 28px;
        margin-bottom: 10px;

        // &:before {
        //   width: 22px;
        //   height: 22px;
        //   bottom: 25px;
        //   right: 25px;
        //   left: unset;
        //   top: unset;
        //   z-index: 1;
        //   transform: rotate(99deg);
        // }
      }

      .sale-off {
        font-size: 28px;
        line-height: 29px;
        margin-bottom: 40px;

        &:after {
          width: 0;
          height: 0;
          top: unset;
          left: unset;
        }
      }

      .subtitle, .disclaimer-text {
        font-size: 14px;
        line-height: 16px;
      }

      .btn-apply {
        padding: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 40px;
      }
    }
`}
`;
