/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  nHaas55,
  black,
  green,
  nHaasTx65,
  grotesk,
} from '../../../styles/theme';
import { BannerConfig } from '../../types/Setting';

export const BannerWrapper = styled.div<{ bannerPopup: BannerConfig }>`
${(props) =>
    props.bannerPopup
    && `
  .banner {
    color: ${black};
    background: ${green};
    text-transform: uppercase;
    line-height: 1.2;
    font-size: 11px;
    font-family: ${nHaasTx65};
    letter-spacing: 2.2px;
    padding: 8px 5px;
    text-align: center;
    font-weight: 400;
    align-items: center;

    .link-text {
      color: ${black};
      background: transparent;
      border: none;
      padding: 0px;
      text-transform: uppercase;
      line-height: 1.2;
      font-size: 11px;
      font-family: ${nHaasTx65};
      letter-spacing: 2.2px;
      text-align: center;
      font-weight: 400;
    }
    img {
      width: 30px;
    }
    .invi-friend {
      a {
        text-align: center;
        display: block;
        padding: 0 20px;
        &:hover {
          color: unset;
        }
        font-family: ${nHaasTx65};
        letter-spacing: 2px;
      }
      span {
        font-family: ${nHaas55};
        text-decoration: underline;
        font-weight: normal;
      }
    }
    .invi-friend:hover {
      cursor: pointer;
      text-decoration: underline;
      font-weight: 700;
    }
    .no-acct-content {
      line-height: 1.4;
      font-family: ${grotesk};
      .top-country-flag {
        margin-right:10px;
      }
      font-size: 11px;
      span {
        font-weight: bold;
        
      }
      .ship-to {
        font-size: 11px;
        :after{
          content: ' - ';
          font-weight: 400;
        }
      }

      @media only screen and (max-width: 992px) {
        max-width: 500px;
        margin: auto;
        text-transform: none;
        letter-spacing: 0;
        .top-country-flag {
          width:35px;
          margin-bottom:4px;
        }
        .ship-to {
          text-transform: uppercase;
          font-size: 16px;
          letter-spacing: 0;
          :after{
            content: '';
          }
        }
        .au-made {
          font-size: 16px;
          white-space: pre;
          font-weight: 400;
        }
      }
    }
  }
  
  .hide-banner {
    display: none;
  }
  .black {
    display: block;
    background: ${props.bannerPopup.bannerColor};
    span {
      u {
        text-decoration: ${props.bannerPopup.bannerLinkTextUnderline ? 'underline' : 'unset'};
      }
    }
    span:hover{
      cursor: pointer;
    }
  }
`}`;
