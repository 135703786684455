import React, { useEffect } from 'react';
import multiply from 'lodash/multiply';
import Link from 'next/link';
import { Country } from '../../types/Country';
import { Setting } from '../../types/Setting';

type Props = {
  className: string,
  country: Country;
  setting: Setting;
  setBannerTextChange: React.Dispatch<React.SetStateAction<boolean>>;
};

const AccountPageBanner = ({
  className, country, setting, setBannerTextChange,
}: Props): JSX.Element => {
  useEffect(() => {
    setBannerTextChange(true);
  }, []);
  const referralExchange = Math.round(Number(multiply(setting.referralCredit, country.exchangeRate).toFixed(2)));
  const isDefaultCountry = country.country === 'AU';

  return (
    <div className={className} id="text_banner">
      <div className="invi-friend banner-content">
        <Link href="/myaccount/my-rewards?openpopup=true" passHref>
          <a href="/myaccount/my-rewards">
            <span>invite a friend</span> & earn{' '}
            {isDefaultCountry ? `$${setting.referralCredit}` : `AU$${setting.referralCredit} (${country.country + country.currencyPrefix + referralExchange})`}{' '}
            credits off your next month
          </a>
        </Link>
      </div>
    </div>
  );
};

export default AccountPageBanner;
