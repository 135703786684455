/* eslint-disable react/no-danger */
import React from 'react';
import Link from 'next/link';
import {
  Col, Dropdown, DropdownButton, Row,
} from 'react-bootstrap';
import { FACEBOOK_LINK, FAQ_LINK, INSTAGRAM_LINK, QUIZ_BTN_PRIMARY_TEXT, QUIZ_BTN_RE_QUIZ_TEXT } from '../../common/const';
import { User } from '../../types/User';
import { WrapperHeader } from '../../pagestyles/components/Header';
import { Setting } from '../../types/Setting';
import { sendGa4Event } from '../../common/GTM';
import Button from '../common/button/Button';

type Props = {
  accountItems: () => JSX.Element,
  closeAllMenu: () => void,
  cartLength: number;
  isHoverLearn: boolean,
  isShowSideBar: boolean,
  isRedoText: boolean,
  logOutAndCloseMenu: () => void,
  showCart: boolean,
  showMenuName: () => void,
  showSideBar: () => void,
  showMenuHigher: () => void,
  setIsHoverLearn: React.Dispatch<React.SetStateAction<boolean>>,
  sidebarLevel2Name: boolean,
  surveyPageRouter: (event: string) => void,
  sidebarLevel2: boolean,
  name: string,
  navbarClasses: string[],
  user: User | null,
  styleLogo: string,
  takeQuizBtnClass: string,
  productMenuName: string,
  setting: Setting,
};
const NewHeader = ({
  accountItems,
  closeAllMenu,
  cartLength,
  isHoverLearn,
  isShowSideBar,
  isRedoText,
  logOutAndCloseMenu,
  setIsHoverLearn,
  showCart,
  showMenuName,
  showSideBar,
  showMenuHigher,
  sidebarLevel2Name,
  surveyPageRouter,
  sidebarLevel2,
  name,
  navbarClasses,
  user,
  styleLogo,
  takeQuizBtnClass,
  productMenuName,
  setting
}: Props): JSX.Element => (
  <WrapperHeader>
    <header className={navbarClasses.join(' ')}>
      <Row>
        <Col className="column1" xs={3} sm={4} md={4} lg={5} xl={4}>
          <div className="nav-left">
            <div className="nav-left__item">
              <Link href="/products">
                <a href="/products" className="button-library">
                  {productMenuName}
                </a>
              </Link>
            </div>
            <DropdownButton
              id="dropdown-learn"
              className="nav-left__item"
              title="Learn"
              onMouseEnter={() => setIsHoverLearn(true)}
              onMouseLeave={() => setIsHoverLearn(false)}
              show={isHoverLearn}
            >
              <Link href="/about" passHref>
                <Dropdown.Item className="about"> About us</Dropdown.Item>
              </Link>
              <Link href="/sustainability" passHref>
                <Dropdown.Item className="sustainability">
                  Sustainability
                </Dropdown.Item>
              </Link>
              <Dropdown.Item
                target="_blank"
                href={FAQ_LINK}
                rel="noopener noreferrer"
                className="faqs"
              >
                FAQs
              </Dropdown.Item>
              <Link href="/blog" passHref>
                <Dropdown.Item className="blog text-left">
                  Blog
                </Dropdown.Item>
              </Link>
              <Link href="/reviews" passHref>
                <Dropdown.Item className="reviews text-left">
                  Reviews
                </Dropdown.Item>
              </Link>
            </DropdownButton>
            {setting.giftCardEnabled && (
              <div className="nav-left__item">
                <Link href="/gift-card">
                  <a href="/gift-card" className="button-gift-card">
                    Send e-gift
                  </a>
                </Link>
              </div>
            )}
          </div>
          <div className="navbar-mobile">
            <div className="navbar-icon">
              <button type="button" onClick={showSideBar}>
                <div id="nav-icon1">
                  <span />
                  <span />
                  <span />
                </div>
              </button>
            </div>
            <nav className={isShowSideBar ? 'nav-menu active' : 'nav-menu'}>
              <ul className="nav-items">
                <li
                  className="nav-toggle d-flex justify-content-between"
                  aria-hidden="true"
                >
                  <button
                    type="button"
                    className="icon-close"
                    aria-label="close"
                    onClick={showSideBar}
                  />
                </li>
                <li
                  className="nav-toggle"
                  onClick={showSideBar}
                  aria-hidden="true"
                >
                  <Link href="/products" passHref>
                    <a href="/products" className="button-library">
                      {productMenuName}
                    </a>
                  </Link>
                </li>
                {user && (
                  <li
                    className="nav-toggle referral-credit"
                    onClick={showSideBar}
                    aria-hidden="true"
                  >
                    <Link href="/myaccount/my-rewards" passHref>
                      <a href="/myaccount/my-rewards">Get $30 Off</a>
                    </Link>
                  </li>
                )}
                <li className="nav-toggle">
                  <button
                    type="button"
                    className="btn-learn"
                    id="dropdown-learn"
                    onClick={showMenuHigher}
                  >
                    Learn <span className="chevron-right" />
                  </button>
                  <nav
                    className={
                      sidebarLevel2 ? 'nav-menu active' : 'nav-menu'
                    }
                  >
                    <ul className="nav-items">
                      <li
                        className="nav-toggle title-menu"
                        aria-label="Learn"
                        onClick={showMenuHigher}
                        aria-hidden="true"
                      >
                        <button
                          type="button"
                          className="btn-learn"
                          aria-label="Learn"
                        >
                          <span className="chevron-left" />
                        </button>
                      </li>
                      <li
                        className="nav-toggle"
                        onClick={closeAllMenu}
                        aria-hidden="true"
                      >
                        <Link href="/about" passHref>
                          <a href="/about" className="about">
                            About Us
                          </a>
                        </Link>
                      </li>
                      <li
                        className="nav-toggle"
                        onClick={closeAllMenu}
                        aria-hidden="true"
                      >
                        <Link href="/sustainability" passHref>
                          <a
                            href="/sustainability"
                            className="sustainability"
                          >
                            Sustainability
                          </a>
                        </Link>
                      </li>
                      <li
                        className="nav-toggle"
                        onClick={closeAllMenu}
                        aria-hidden="true"
                      >
                        <Link href={FAQ_LINK} passHref>
                          <a
                            target="_blank"
                            href={FAQ_LINK}
                            rel="noopener noreferrer"
                            className="faqs"
                          >
                            FAQs
                          </a>
                        </Link>
                      </li>
                      <li
                        className="nav-toggle"
                        onClick={closeAllMenu}
                        aria-hidden="true"
                      >
                        <Link href="/blog" passHref>
                          <a href="/blog" className="blog">
                            Blog
                          </a>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </li>
                <li
                  className="nav-toggle reviews"
                  onClick={showSideBar}
                  aria-hidden="true"
                >
                  <Link href="/reviews" passHref>
                    <a href="/reviews">Reviews</a>
                  </Link>
                </li>
                {setting.giftCardEnabled && (
                  <li
                    className="nav-toggle gift-card"
                    onClick={showSideBar}
                    aria-hidden="true"
                  >
                    <Link href="/gift-card" passHref>
                      <a href="/gift-card">Send e-gift</a>
                    </Link>
                  </li>
                )}
                {user ? (
                  <li className="nav-toggle">
                    <button
                      type="button"
                      className="btn-name"
                      id="dropdown-name"
                      onClick={showMenuName}
                    >
                      {name} <span className="chevron-right" />
                    </button>
                    <nav
                      className={
                        sidebarLevel2Name ? 'nav-menu active' : 'nav-menu'
                      }
                    >
                      <ul className="nav-items">
                        <li
                          className="nav-toggle title-menu"
                          aria-label={name}
                          onClick={showMenuName}
                          aria-hidden="true"
                        >
                          <button
                            type="button"
                            className="btn-name"
                            aria-label={name}
                          >
                            <span className="chevron-left-name" /> <p className="chevron-left-name-text">{name}</p>
                          </button>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={() => {
                            closeAllMenu(); 
                            sendGa4Event('accDropdownMenu', 'click', 'myProfile');
                          }}
                          aria-hidden="true"
                        >
                          <Link href="/myaccount/profile-customer" passHref>
                            <a href="/myaccount/profile-customer" className="about">
                              Profile
                            </a>
                          </Link>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={() => {
                            closeAllMenu(); 
                            sendGa4Event('accDropdownMenu', 'click', 'myPack');
                          }}
                          aria-hidden="true"
                        >
                          <Link href="/myaccount/next-delivery" passHref>
                            <a
                              href="/myaccount/next-delivery"
                              className="sustainability"
                            >
                              My pack
                            </a>
                          </Link>
                        </li>
                        <li
                          className="nav-toggle"
                          onClick={logOutAndCloseMenu}
                          aria-hidden="true"
                        >
                          <a
                            href="/"
                          >
                            Log Out
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </li>
                ) : (
                  <li
                    className="nav-toggle"
                    onClick={showSideBar}
                    aria-hidden="true"
                  >
                    <Link href="/auth/login" passHref>
                      <a href="/auth/login">My Account</a>
                    </Link>
                  </li>
                )}
                <div className="social">
                  <a
                    className="social-link instagram"
                    href={INSTAGRAM_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                  <a
                    className="social-link facebook"
                    href={FACEBOOK_LINK}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Facebook
                  </a>
                </div>
              </ul>
              <div className="bottom-nav-button-container">
                <div className="bottom-nav-p-container">
                  {isRedoText
                    ? 'Have your lifestyle, diet or health needs changed?'
                    : 'Not sure where to start?'}
                </div>
                <div className="bottom-nav-button">
                  <Button
                    type='alternative'
                    aria-label='Start Quiz'
                    onClick={(e) => {
                      e.preventDefault();
                      surveyPageRouter(isRedoText ? 'click-on-retake-quiz-in-mobile-hamburger-menu' : 'click-take-quiz-in-mobile-hamburger-menu');
                    }}
                    className='button-quiz'
                  >
                    {isRedoText ? QUIZ_BTN_RE_QUIZ_TEXT : QUIZ_BTN_PRIMARY_TEXT}
                  </Button>
                </div>
              </div>
            </nav>
          </div>
        </Col>
        <Col className="column2" xs={6} sm={4} md={4} lg={3} xl={4}>
          <div className="logo">
            <Link href="/">
              <div className={styleLogo} />
            </Link>
          </div>
        </Col>
        <Col className="column3" xs={3} sm={4} md={4} lg={4} xl={4}>
          <div className="nav-right">
            {!user && (
              <>
                <div className="my-account">
                  <Link href="/auth/login">
                    <a href="/auth/login" className="button-login">
                      My Account
                    </a>
                  </Link>
                </div>
              </>
            )}
            {user && (
              <div>
                <div className="my-rewards">
                  <Link href="/myaccount/my-rewards" passHref>
                    <a href="/myaccount/my-rewards">Get $30 Off</a>
                  </Link>
                </div>
              </div>
            )}
            <div className={takeQuizBtnClass}>
              <Button
                type='alternative'
                aria-label='StartQuiz'
                onClick={(e) => {
                  e.preventDefault();
                  surveyPageRouter(isRedoText ? 'click-on-desktop-retake-quiz-cta' : 'click-on-desktop-take-quiz-cta');
                }}
                className='button-quiz'
              >
                {isRedoText ? QUIZ_BTN_RE_QUIZ_TEXT : QUIZ_BTN_PRIMARY_TEXT}
              </Button>
            </div>
            {user ? (
              <>
                {!isShowSideBar && (
                  <>{accountItems()}</>
                )}
              </>
              // <div>{isShowSideBar ? null : accountItems()}</div>
            ) : (
              showCart && (
                <div className="logo-cart">
                  <Link href="/checkout/review">
                    <div className="cart-img">
                      <div className="cart-number"> {cartLength}</div>
                    </div>
                  </Link>
                </div>
              )
            )}
          </div>
        </Col>
      </Row>
    </header>
  </WrapperHeader>
);
export default NewHeader;
