/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import {
  nHaas55,
  black,
  green,
  red,
  solaire,
  offWhite,
  grotesk,
  homemadeApple,
  offWhite5,
} from '../../../styles/theme';

export const DefaultHeaderWrapper = styled.div<{ user: boolean }>`
${(props) => `
  position: fixed;
  width: 100%;
  z-index: 999;

  .sticky-top-menu {
    display: none;
    width: 100%;
    position: fixed;
    height: 51px;
    z-index: 99;
    align-items: center;
    justify-content: center;
    top: 116px;
    border-bottom: 1px solid ${black};
    border-top: 1px solid ${black};
    background: ${offWhite5};
    div {
      display: flex;
      align-items: center;
    }
    a {
      font-family: ${grotesk};
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
    }
    .main-content {
      width: 100vw;
      height: 35px;
      display: flex;
      .blog-page {
        height: 100%;
        width: 23%;
        justify-content: center;
        border-right: 1px solid ${black};
        a {
          font-weight: 450;
        }
      }
      .special-tags {
        height: 100%;
        justify-content: space-around;
        width: 100%;
      }
    }
  }
  .header {
    transition: all 0.5s linear;
    font-family: ${nHaas55};
    font-size: 18px;
    z-index: 1000;
    .row {
      padding-left: 10px;
      padding-right: 15px;
    }
    div {
      display: flex;
      margin: auto 0;
      white-space: nowrap;
      a {
        font-family: ${nHaas55};
        font-size: 18px;
      }
      .dropdown {
        Button {
          background: none;
          border: none;
          color: ${black};
        }
        Button:hover {
          color: ${green};
        }
        Button:focus {
          box-shadow: none;
        }
        .btn {
          min-width: 75px;
        }
        div {
          display: block;
          background: ${green};
          padding: 0;
          height: auto;
          a {
            border: 1px solid black;
            height: 43px;
            display: flex;
            align-items: center;
          }
          a:hover {
            background: ${red};
          }
        }
        .dropdown-menu {
          border: 1px solid ${black};
          border-bottom: none;
          border-radius: unset;
          min-width: 213px;

          a {
            padding: 11px 30px 12px 18px;
            font-family: ${nHaas55};
            border: unset;
            border-bottom: 1px solid ${black};
            position: relative;
            height: unset;

            &:nth-child(5) {
              padding: 9px 0 10px;
              text-align: center;
              display: block;
              &:before {
                border: none;
              }
            }
          }
          a.text-left {
            text-align: left;
            padding-left: 18px;
          }
          a.chevron-right {
            &:before {
              transform: rotate(45deg);
              border-style: solid;
              border-width: 1.5px 1.5px 0 0;
              content: '';
              display: inline-block;
              height: 12px;
              width: 11px;
              position: absolute;
              right: 17px;
            }
          }
        }
      }
    }
    .button-nav-right {
      display: none;
    }
    
    .new-tag {
      margin-left: 4px;
      border-radius: 5px;
      background: rgba(245, 120, 90, 0.10);
      display: inline-flex;
      width: 48px;
      height: 28px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #F5785A;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    
    @media screen and (max-width: 992px) {
      .button-nav-right {
        display: flex;
        width: 178px;
        height: 39px;
        background: #637C5A;
        border-radius: 16px;
        justify-content: center;
        align-items: center;
        margin-left: 2vw;
        &:hover {
          background: #F5785A;
        }
        .button-quiz {
          font-family: ${grotesk};
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #FAF9F6;
          text-align: center;
        }
      }
      .mobile-style {
        width: 138px;
        height: 39px;
        padding: 10px 20px;
        border-radius: 16px;
      }
    }
    .logo {
      width: 100%;
      display: flex;
      justify-content: center;
      .logo-img, .logo-img-with-cart {
        width: 200px;
        cursor: pointer;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url('/assets/images/Logo.svg');
      }
      .logo-img {
        background-size: 200px;
        height: 70px;
      }
      .logo-img-with-cart {
        background-size: 180px;
        height: 58px;
      }
    }
    .logo-cart {
      width: 70px;
      cursor: pointer;
      margin: 0;
      justify-content: flex-end;
      display: flex;
      align-items: center;
      z-index: 9999;
      .cart-img {
        width: 38px;
        background-image: url('/assets/images/CartIcon.svg');
        background-position: center;
        background-repeat: no-repeat;
        height: 62px;
        position: relative;
        .cart-number {
          position: absolute;
          top: 15px;
          right: 0;
          font-size: 10px;
          width: 13px;
          text-align: center;
          background: #f5785a;
          border-radius: 50%;
          height: 13px;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .dropdown-toggle {
      &:after {
        display: none;
      }
    }
    .nav-right {
      width: 100%;
      justify-content: flex-end;

      .account {
        button {
          font-family: ${homemadeApple};
        }
      }

      .recommendation {
        margin-left: 30px;
        a {
          font-family: ${homemadeApple};
        }
      }

      .gift-card {
        margin-right: 35px;
      }
      .my-rewards {
        margin-right: 35px;
      }
    }
    .background-quiz {
      padding: 25px 0;
      background: url(/assets/images/HW_Circle.png) no-repeat center;
      background-size: cover;

      a {
        letter-spacing: 0.26px;
        padding: 3px 34px;
      }
    }
    .background-quiz:hover {
      color: ${green};
    }
    .nav-left {
      width: 100%;

      .nav-left__item:nth-child(2) {
        padding: 0 30px;
      }
    }
    .navbar-mobile {
      display: none;
      .navbar-icon {
        background: none;
        Button {
          background: none;
          border: none;
          left: 0;
          top: 5px;
          padding: 0;
        }
        #nav-icon1 {
          transform: rotate(0deg);
          transition: 0.5s ease-in-out;
          cursor: pointer;
          border: 1px solid ${black};
          height: 33px;
          width: 33px;
          position: relative;
          border-radius: 50%;
          display: flex;
          justify-content: center;
        }
      }

      #nav-icon1 span {
        display: block;
        position: absolute;
        height: 1.5px;
        width: 60%;
        background: ${black};
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
      }

      #nav-icon1 span:nth-child(1) {
        top: 8px;
      }

      #nav-icon1 span:nth-child(2) {
        top: 14px;
      }

      #nav-icon1 span:nth-child(3) {
        top: 20px;
      }

      #nav-icon1.open span:nth-child(1) {
        top: 18px;
        transform: rotate(135deg);
      }

      #nav-icon1.open span:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      #nav-icon1.open span:nth-child(3) {
        top: 18px;
        transform: rotate(-135deg);
      }
    }
    .nav-menu {
      background: ${green};
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      position: fixed;
      top: 0;
      left: -200%;
      transition: 850ms;
      z-index: 100;
      
      .nav-items {
        padding: 0;
        width: 100%;
        span {
          font-size: 32px;
        }
        .title-menu {
          position: relative;
          .title-menu-text {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }

        .nav-toggle {
          display: flex;
          justify-content: start;
          align-items: center;
          list-style: none;
          height: 60px;
          background: ${green};
          width: 100%;
          height: 65px;
          a {
            margin: 0 1.2rem;
            text-decoration: none;
            font-size: 32px;
            width: 95%;
            height: 100%;
            display: flex;
            align-items: center;
            font-family: ${solaire};
            span {
              background-size: contain !important;
              padding: 22px;
              background: url(/assets/images/HW_Circle3.png) no-repeat center;
              background-position-y: 30% !important;
            }
          }
          a:hover {
            background: ${green};
            @media only screen and (max-width: 992px) {
              color: ${black};
            }
          }
          .icon-close {
            border: 1px solid ${black};
            border-radius: 50%;
            margin: 0 1.2rem;
            padding: 0 8px;
            background: transparent;
            font-family: ${nHaas55};
            position: relative;
            width: 33px;
            height: 33px;
            &::before {
              content: '';
              width: 1px;
              height: 60%;
              background-color: ${black};
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(45deg);
              border-radius: 2px;
            }
            &::after {
              content: '';
              width: 1px;
              height: 60%;
              background-color: ${black};
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(-45deg);
              border-radius: 2px;
            }
          }
          .chevron-right:before {
            transform: rotate(45deg);
            border-style: solid;
            border-width: 1.5px 1.5px 0 0;
            content: '';
            display: inline-block;
            height: 15px;
            width: 0.45em;
          }
          .chevron-left:before {
            transform: rotate(-135deg);
            border-style: solid;
            border-width: 2px 2px 0 0;
            content: '';
            display: inline-block;
            height: 15px;
            width: 0.45em;
          }
          .chevron-left:after {
            content: 'Learn';
            display: inline-block;
            margin: auto;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
          }
        }
        .nav-toggle:nth-child(1) {
          border-bottom: 1px solid;
        }
      }
      .social-link {
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        display: inline-block;
        overflow: hidden;
        text-indent: -100em;
        margin-right: 24px;
        left: 1.2rem;
        bottom: 20px;
        position: absolute;
        &.facebook {
          background-image: url('/images/facebook.png');
          background-size: 9px 18px;
          margin: 0 30px;
        }
        &.instagram {
          background-image: url('/images/instagram.png');
          background-size: 18px 18px;
        }
      }
    }
    .btn-learn {
      border: none;
      background: none;
      font-size: 32px;
      padding: 0 1.2rem;
      display: flex;
      justify-content: space-between;
      width: 100%;
      font-family: ${solaire};
      position: relative;
      color: ${black};
    }
    .active {
      left: 0;
      transition: 350ms;
    }
    .referral-credit {
      margin-right: 10px;
    }
  }
  .scrolled {
    height: 58px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: ${offWhite};
    border-bottom: 2px solid;
    .logo {
      .logo-img, .logo-img-with-cart {
        background-size: 122px;
        height: 35px;
      }
    }
    div {
      height: inherit;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1200px) {
    .nav-left {
      display: none !important;
    }
    .navbar-mobile {
      display: flex !important;
    }
    .nav-right {
      .my-account {
        display: none;
      }
      .referral-credit {
        display: none;
      }
      .my-rewards {
        display: none;
      }
      .gift-card {
        display: none;
      }
    }
    .header {
      .row {
        padding-left: 0;
        padding-right: 0;
      }
      .col {
        padding: 0;
      }
      .account {
        button {
          z-index: 999;
          &:hover {
            color: ${black} !important;
          }
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1350px) {
    .logo {
      padding-left: 80px;
    }
  }

  @media (min-width: 1000px) and (max-width: 1200px) {
    .logo {
      justify-content: flex-start!important;
    }
  }

  @media (min-width: 992px) and (max-width: 1350px) {
    .nav-left {
      padding: 0 0 !important;

      .background-quiz {
        // padding: 29px 0;
      }
    }
  }
  @media only screen and (max-width: 1350px) {
    .header {
      .nav-left {
        .nav-left__item:nth-child(2) {
          padding: 0 20px;
        }
      }
      .logo {
        .logo-img, .logo-img-with-cart {
          width: 150px;
          background-size: 150px;
          height: 60px;
        }
      }
    }
  }
  @media only screen and (max-width: 992px) {
    .header {
      .account {
        button {
          padding: 10px 0 10px;
        }
      }
      .logo {
        .logo-img {
          background-image: url('/assets/images/LogoMark.svg');
          height: 62px;
          background-size: 46px 38px;
        }
        .logo-img-with-cart {
          height: 62px;
        }
        .logo-position {
          background-position: 0;
        }
      }
      .sidebar-active {
        button {
          padding: 20px 0 0;
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .header {
      height: 60px;
      padding-top: ${props.user ? 6 : 0}px;
      .column1 {
        padding-left: 3px;
      }
      .column3 {
        padding-right: 3px;
      }
    }
    .scrolled {
      padding-top: 0px;
    }
  }
  @media only screen and (max-width: 576px) {
    .header {
      logo {
        .logo-img {
          background-size: 90px;
        }
      }

      .column2 {
        padding-left: 0;
        padding-right: 0;
      }

      .nav-right {
        .recommendation {
          margin-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 340px) {
    .header {
      .logo {
        .logo-img {
          background-size: 46px 38px;
        }
        .logo-img-with-cart {
          height: 62px;
          background-size: 100px;
        }
      }
      .column2 {
        padding-left: 0;
        padding-right: 0;
      }

      .nav-right {
        .recommendation {
          margin-right: 10px;
        }
      }
    }
  }
`}`;
