import React, { useEffect } from 'react';
import { Country } from '../../types/Country';

type Props = {
  className: string,
  country: Country;
  setBannerTextChange: React.Dispatch<React.SetStateAction<boolean>>
};

const InternationalBanner = ({
  className, country, setBannerTextChange,
}: Props): JSX.Element => {
  useEffect(() => {
    setBannerTextChange(true);
  }, []);
  let toCountry = country.countryLabel;
  if (country.country === 'GB') {
    toCountry = 'the UK';
  } else if (country.country === 'PH') {
    toCountry = 'the'.concat(' ', country.countryLabel);
  }
  const countriesUseNowText = ['HK', 'MY', 'PH'];
  const nowText = countriesUseNowText.includes(country.country) ? 'now ' : '';

  return (
    <div className={className} id="text_banner">
      <div className="banner-content no-acct-content">
        <span className="ship-to">
          <img className="top-country-flag" src={country.flag} alt="" />
          &nbsp;{nowText}Shipping to {toCountry}
        </span>
        <span className="au-made">
          {' '}
          Using only <span>quality ingredients</span>, made in Australia
        </span>
      </div>
    </div>
  );
};

export default InternationalBanner;
